import React, { CSSProperties, FC } from "react"
import { externalLink } from "@phonero/pux-icons/icons"
import { PuxButton, PuxIcon, PuxTypography } from "@phonero/pux-react"
import { Variant } from "@phonero/pux-core"
import classNames from "classnames"

import css from "./MobileInsuranceLinks.module.scss"

export function GetTermsUrlForProduct(productId: number) {
  return productId === 24711
    ? "https://www.phonero.no/informasjon/mobilforsikringsvilkar"
    : productId === 24712
    ? "https://www.phonero.no/informasjon/tryggforsikringsvilkar"
    : productId === 10700
    ? "https://www.phonero.no/informasjon/trygg-surfing-vilkar"
    : productId === 10672
    ? "https://www.phonero.no/informasjon/nettslettvilkar"
    : productId === 10673
    ? "https://www.phonero.no/informasjon/idtyverivilkar"
    : ""
}

export interface MobileInsuranceTermsLinkProps {
  productId: number
  styleType: "button" | "anchor"
  buttonColor?:
    | "primary"
    | "secondary"
    | "tertiary"
    | "success"
    | "warning"
    | "danger"
    | "light"
    | "medium"
    | "dark" // PuxButton color
  buttonFill?: "solid" | "outline" | "clear" // PuxButton fill
}

export const MobileInsuranceTermsLink: FC<MobileInsuranceTermsLinkProps> = (
  props: MobileInsuranceTermsLinkProps
) => {
  const url = GetTermsUrlForProduct(props.productId)
  const text = "Se vilkår"
  const icon = <PuxIcon icon={externalLink} />
  if (props.styleType === "anchor") {
    return (
      <div className={css.insuranceLinkAnchor}>
        <a href={url} target="_blank" rel="noopener noreferrer">
          {text}
          {icon}
        </a>
      </div>
    )
  }
  return (
    <PuxButton
      className={css.insuranceLinkButton}
      strong
      fill={props.buttonFill ?? "clear"}
      size="small"
      color={props.buttonColor ?? "primary"}
      href={url}
      target="_blank"
      rel="noopener noreferrer"
    >
      {text}
      {icon}
    </PuxButton>
  )
}

export interface MobileInsuranceInlineTermsLinkProps {
  productId: number
  variant: Variant
  className?: string
  style?: CSSProperties
}

export const MobileInsuranceInlineTermsLink: FC<
  MobileInsuranceInlineTermsLinkProps
> = (props: MobileInsuranceInlineTermsLinkProps) => {
  const url = GetTermsUrlForProduct(props.productId)
  return (
    <PuxTypography
      variant={props.variant}
      className={classNames(css.insuranceLinkAnchorInline, props.className)}
      style={props.style}
    >
      Ved å bestille tjenesten godkjenner du
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className={css.mandatoryLinkInsurance}
      >
        vilkår for bruk
        <PuxIcon icon={externalLink} />
      </a>
    </PuxTypography>
  )
}
