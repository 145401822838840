import { FC } from "react"
import { externalLink } from "@phonero/pux-icons/icons"
import { PuxButton, PuxIcon } from "@phonero/pux-react"

import css from "./PhoneroCloudLinks.module.scss"

export interface ProductLinkProps {
  text: string
  url: string
  styleType: "button" | "anchor"
  buttonColor?:
    | "primary"
    | "secondary"
    | "tertiary"
    | "success"
    | "warning"
    | "danger"
    | "light"
    | "medium"
    | "dark" // PuxButton color
  buttonFill?: "solid" | "outline" | "clear" // PuxButton fill
}

export const ProductLink: FC<ProductLinkProps> = (props: ProductLinkProps) => {
  const url = props.url
  const text = props.text
  const icon = <PuxIcon icon={externalLink} />

  if (!url || !text) return <></>

  if (props.styleType === "anchor") {
    return (
      <div className={css.cloudLinkAnchor}>
        <a href={url} target="_blank" rel="noopener noreferrer">
          {text}
          {icon}
        </a>
      </div>
    )
  }
  return (
    <PuxButton
      className={css.cloudLinkButton}
      strong
      fill={props.buttonFill ?? "clear"}
      size="small"
      color={props.buttonColor ?? "primary"}
      href={url}
      target="_blank"
      rel="noopener noreferrer"
    >
      {text}
      {icon}
    </PuxButton>
  )
}
