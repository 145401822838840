import {
  DateFormat,
  PuxButton,
  PuxIcon,
  PuxLabel,
  PuxSpinner,
  PuxText,
  useBreakpoint,
} from "@phonero/pux-react"
import css from "./PhoneroCloudDetail.module.scss"
import Price from "../Other/Price"
import {
  useCurrentSubscriptionId,
  graphqlMutationToast,
  useAppTranslations,
} from "../util"
import { action, costsharing, trash } from "@phonero/pux-icons/icons"
import { Product } from "../ProductList/ProductItem"
import cx from "classnames"
import { GetPhoneroCloudUrl, PhoneroCloudLinks, PhoneroCloudProductId } from "."
import { useMutation } from "@apollo/client"
import {
  AddPhoneroCloudDocument,
  ResetPollIntervalOnPhoneroCloudDocument,
} from "../queries/AddPhoneroCloud.generated"
import { useFeatureIsOn } from "@growthbook/growthbook-react"
import { useState } from "react"
import { PhoneroCloudDeactivate } from "./PhoneroCloudDeactivate"
import { DeactivateServiceDocument } from "../queries"
import {
  ApiProductState,
  DeactivationRule,
  PhoneroCloud,
} from "@phonero/common-graphql/types"
import { CancelDeactivateServiceDocument } from "../queries/CancelDeactivateService.generated"
import { de } from "date-fns/locale"

enum CloudState {
  Registered = "Registered",
  Activating = "Activating",
  Active = "Active",
  Deactivating = "Deactivating",
}

// This component displays the detail of the PhoneroCloud service.
// Usage
// 1. It can be used in a promotional state where more information is displayed.
// 2. It can be used in a carousel with other promotion products.
// 3. It can be used in the standard service list with a list of services.
// Action
// On activating an Available or Registered product the backend activation process is trigged, then the user is redirected to sky.phonero.no/start to complete setting up the service.
// Deactivation is (by design) yet not supported.
export const PhoneroCloudDetail = ({
  product,
  displayMode = "serviceList",
  showPrice = true,
  onActivated,
  onDeactivated,
  onCancelDeactivation,
}: {
  product: Product
  displayMode: "promotion" | "promotionCarousel" | "serviceList"
  showPrice: boolean
  onActivated?: (product: Product) => void
  onDeactivated?: (product: Product) => void
  onCancelDeactivation?: (product: Product) => void
}) => {
  const { subscriptionId } = useCurrentSubscriptionId()
  const isDesktop = useBreakpoint("md", { useMin: true })
  const price = product?.price || undefined
  const { t, i18n } = useAppTranslations()
  const [showDeactivateModal, setShowDeactivateModal] = useState(false)
  const [scheduledDeactivationDate, setScheduledDeactivationDate] = useState<
    Date | undefined
  >(undefined)
  const isDeactivateEnabled = useFeatureIsOn("dpw-phonero-cloud-deactivate")

  const headerMode = displayMode === "serviceList" ? "neutral" : "promotion"

  // future deactivation date from the backend or if the service is in the process of deactivation
  const futureDeactivationDateValue = (product as PhoneroCloud)
    ?.futureDeactivationDate
  const futureDeactivationDate = !!futureDeactivationDateValue
    ? new Date(futureDeactivationDateValue)
    : undefined
  const deactivationDate = futureDeactivationDate ?? scheduledDeactivationDate

  const [activateCloud] = useMutation(AddPhoneroCloudDocument, {
    ...graphqlMutationToast(t, "phonero-cloud"),
  })

  const [resetPollInterval] = useMutation(
    ResetPollIntervalOnPhoneroCloudDocument,
    {}
  )

  const [deactivateCloud, deactivateQuery] = useMutation(
    DeactivateServiceDocument,
    {
      ...graphqlMutationToast(t, "phonero-cloud"),
    }
  )

  const [cancelDeactivateCloud, cancelDeactivateQuery] = useMutation(
    CancelDeactivateServiceDocument,
    {
      ...graphqlMutationToast(t, "phonero-cloud"),
    }
  )

  // When deactivating we set the future deactivation date
  // When cancelling deactivation we remove the future deactivation date
  // Set as loading while we wait for the mutation to be carried out and we have reloaded the product.
  const loading =
    deactivateQuery.loading ||
    cancelDeactivateQuery.loading ||
    (deactivateQuery.called && !futureDeactivationDate) || // expect to set the future deactivation date
    (cancelDeactivateQuery.called && !!futureDeactivationDate) // expect to remove the future deactivation date

  function onActivatePhoneroCloud(product: Product) {
    const productId = product?.productId
    const activateStates = [
      ApiProductState.Available,
      ApiProductState.Registered,
    ]

    if (
      !!product.state &&
      activateStates.includes(product.state) &&
      productId === PhoneroCloudProductId
    ) {
      if (!subscriptionId) {
        throw new Error("no subscriptionId (disable)")
      }

      openCloudApp()
      activateCloud({
        variables: { input: { subscriptionId, productId } },
        refetchQueries: ["SubscriptionServices"],
        awaitRefetchQueries: true,
      })

      onActivated && onActivated(product)
    } else {
      console.log("onActivatePhoneroCloud - failed", product)
    }
  }

  function onActivatingPhoneroCloud(product: Product) {
    const productId = product?.productId
    const cloudContractProductId = product?.id
    const activatingStates = [
      ApiProductState.Ordered,
      ApiProductState.Startactivation,
      ApiProductState.Activating,
    ]

    if (
      !!product.state &&
      activatingStates.includes(product.state) &&
      productId === PhoneroCloudProductId
    ) {
      if (!subscriptionId) {
        throw new Error("no subscriptionId (disable)")
      }

      openCloudApp()
      resetPollInterval({
        variables: { phoneroCloudId: +cloudContractProductId },
      })
    } else {
      console.log("onActivatingPhoneroCloud - failed", product)
    }
  }

  function onDeactivatePhoneroCloud(product: Product) {
    deactivateCloud({
      variables: { input: { id: product.id } },
      refetchQueries: ["SubscriptionServices"],
      awaitRefetchQueries: true,
    })

    // Don't close modal, it shows a confirmation dialog
    // setShowDeactivateModal(false)

    // Display expected deactivation date to end of month
    const service = product as PhoneroCloud
    if (!!service && !!service.deactivationRule) {
      const { value: rule, forecastDate: deactivationForecastDate } =
        service.deactivationRule

      if (!!deactivationForecastDate && rule === DeactivationRule.Endofmonth) {
        setScheduledDeactivationDate(new Date(deactivationForecastDate))
      }
    }

    cancelDeactivateQuery.reset()
    onDeactivated && onDeactivated(product)
  }

  function onCancelDeactivatePhoneroCloud(product: Product) {
    cancelDeactivateCloud({
      variables: { input: { id: product.id } },
      refetchQueries: ["SubscriptionServices"],
      awaitRefetchQueries: true,
    })

    // Remove scheduled deactivation date
    setScheduledDeactivationDate(undefined)
    deactivateQuery.reset()
    onCancelDeactivation && onCancelDeactivation(product)
  }

  function openCloudApp() {
    // window.open does not work on IPhone. Trick to use a timeout to make the call execute on the main thread
    setTimeout(() => {
      window.open(GetPhoneroCloudUrl(), "_blank", "noopener noreferrer")
    })
  }

  const getAlignment = (
    displayMode: "promotion" | "promotionCarousel" | "serviceList"
  ) => {
    switch (displayMode) {
      case "promotion":
        return isDesktop ? "start" : "center"
      case "promotionCarousel":
        return "center"
      case "serviceList":
        return "center"
      default:
        return "start"
    }
  }

  const getActivationMode = (
    state: ApiProductState | undefined,
    deactivationDate: Date | string | undefined
  ) => {
    if (!state) return undefined
    switch (state) {
      case ApiProductState.Registered:
      case ApiProductState.Available:
        return CloudState.Registered
      case ApiProductState.Ordered:
      case ApiProductState.Activating:
      case ApiProductState.Startactivation:
        return CloudState.Activating
      case ApiProductState.Active:
        return !deactivationDate ? CloudState.Active : CloudState.Deactivating
      case ApiProductState.Deactivating:
        return CloudState.Deactivating
      default:
        return undefined
    }
  }

  const getStatusText = (
    activationMode: CloudState,
    deactivationDate: Date | string | undefined
  ) => {
    switch (activationMode) {
      case CloudState.Registered:
        return {
          status: "Tjenesten er ikke aktivert",
          description:
            "Ved å aktivere tjenesten så blir du sendt videre for å laste ned Phonero Sky-appen.",
        }
      case CloudState.Activating:
        return {
          status: "Tjenesten krever registrering",
          description:
            "Vi ser at du har aktivert tjenesten, men ikke fullført registreringen. Du blir sendt videre til Phonero Sky-appen for å fullføre.",
        }
      case CloudState.Active:
        return {
          status: "Tjenesten er aktivert",
          description: undefined,
        }
      case CloudState.Deactivating:
        return {
          status: `Tjenesten avsluttes ${i18n.format(
            deactivationDate,
            DateFormat.Date
          )}`,
          description: undefined,
        }
    }
  }

  // simplified by reducing the number of states
  const activationMode = getActivationMode(product.state, deactivationDate)
  if (activationMode === undefined) return <></>

  // The alignment of the content is different depending on the display mode
  const alignment = getAlignment(displayMode)
  const { status, description } = getStatusText(
    activationMode,
    deactivationDate
  )

  return (
    <>
      {displayMode !== "serviceList" && (
        <div
          className={cx(
            css.main,
            alignment === "start" ? css.alignStart : css.alignCenter
          )}
        >
          {headerMode === "promotion" && (
            <div>
              <PuxLabel wrap style={{ fontSize: "2.0rem", fontWeight: 900 }}>
                {product.name}
              </PuxLabel>
            </div>
          )}
          {headerMode === "neutral" && (
            <div>
              <PuxLabel wrap style={{ fontSize: "1.7rem" }}>
                {product.name}
              </PuxLabel>
            </div>
          )}
          {!!showPrice && !price && (
            <p style={{ marginBlock: 6 }}>
              <PuxText color="primary" style={{ fontStyle: "italic" }}>
                Tjenesten er inkludert
              </PuxText>
            </p>
          )}
          {!!showPrice && !!price && (
            <div>
              <Price
                value={price}
                action={["freeIfZero"]}
                priceStyle={{
                  fontWeight: 900,
                  textTransform: "capitalize",
                  fontSize: isDesktop ? 19 : 17,
                }}
              />
              <PuxText>{"/mnd."}</PuxText>
            </div>
          )}
          <p style={{ marginBlock: 6 }}>
            <PuxText style={{ opacity: 0.6 }}>{product.description} </PuxText>
          </p>
          {activationMode === CloudState.Registered && (
            <PuxButton
              shape="round"
              onClick={() => onActivatePhoneroCloud(product)}
            >
              <PuxText>{"Aktiver"}</PuxText>
              <PuxIcon icon={action} slot="end" />
            </PuxButton>
          )}
          {activationMode === CloudState.Activating && (
            <PuxButton
              shape="round"
              onClick={() => onActivatingPhoneroCloud(product)}
            >
              <PuxText>{"Fullfør registrering"}</PuxText>
              <PuxIcon icon={action} slot="end" />
            </PuxButton>
          )}
          {activationMode === CloudState.Active && (
            <PhoneroCloudLinks
              hasActiveProduct={product?.state === ApiProductState.Active}
              productId={product?.productId || 0}
              styleType="button"
            />
          )}
          {activationMode === CloudState.Active && !!isDeactivateEnabled && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <PuxButton
                style={{ minWidth: "150px", aligneItem: "center" }}
                onClick={() => setShowDeactivateModal(true)}
                size="default"
                color="light"
              >
                <PuxText>{"Avslutt"}</PuxText>
                <PuxIcon icon={trash} slot="end" />
              </PuxButton>
            </div>
          )}
        </div>
      )}
      {/* Specialized implementation for showing in service modal */}
      {displayMode === "serviceList" && (
        <div
          className={cx(
            css.main,
            alignment === "start" ? css.alignStart : css.alignCenter
          )}
        >
          <div>
            <PuxLabel wrap style={{ fontSize: "1.7rem", textAlign: "center" }}>
              {status}
            </PuxLabel>
          </div>
          <p style={{ marginBlock: "2rem", textAlign: "center" }}>
            <PuxText style={{ opacity: 0.6 }}>{description} </PuxText>
          </p>
          {activationMode === CloudState.Registered && (
            <PuxButton onClick={() => onActivatePhoneroCloud(product)}>
              <PuxText>{"Aktiver tjenesten"}</PuxText>
            </PuxButton>
          )}
          {activationMode === CloudState.Activating && (
            <PuxButton onClick={() => onActivatingPhoneroCloud(product)}>
              <PuxText>{"Fullfør registrering"}</PuxText>
            </PuxButton>
          )}
          {activationMode === CloudState.Active && !!isDeactivateEnabled && (
            <PuxButton
              disabled={loading}
              onClick={() => setShowDeactivateModal(true)}
              color="medium"
            >
              <PuxText>{"Avslutt tjenesten"}</PuxText>
            </PuxButton>
          )}
          {activationMode === CloudState.Deactivating &&
            !!isDeactivateEnabled && (
              <PuxButton
                disabled={loading}
                onClick={() => onCancelDeactivatePhoneroCloud(product)}
              >
                <PuxText>{"Angre oppsigelse"}</PuxText>
              </PuxButton>
            )}
          {loading && <PuxSpinner />}
        </div>
      )}

      <PhoneroCloudDeactivate
        product={product}
        futureDeactivationDate={deactivationDate}
        isOpen={showDeactivateModal}
        onDeactivate={onDeactivatePhoneroCloud}
        onDismiss={() => setShowDeactivateModal(false)}
      />
    </>
  )
}
