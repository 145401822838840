import {
  DateFormat,
  PuxButton,
  PuxDialogTitle,
  PuxModal,
  PuxSheet,
  PuxText,
  PuxTypography,
  useTranslation,
} from "@phonero/pux-react"
import css from "./PhoneroCloudDeactivate.module.scss"
import {
  ApiProductState,
  DeactivationRule,
  PhoneroCloud,
} from "@phonero/common-graphql/types"
import { Product } from "../ProductList/ProductItem"
import cx from "classnames"

// This component handles deactivation dialog for PhoneroCloud service.
export const PhoneroCloudDeactivate = ({
  product,
  futureDeactivationDate,
  isOpen,
  onDeactivate,
  onDismiss,
}: {
  product: Product
  futureDeactivationDate?: Date | string | undefined
  isOpen: boolean
  onDeactivate: (product: Product) => void
  onDismiss: () => void
}) => {
  const { i18n } = useTranslation()

  if (!product || !product?.state) return null

  const acceptedStates = [ApiProductState.Active, ApiProductState.Deactivating]
  if (!acceptedStates.includes(product?.state)) return null

  const service = product as PhoneroCloud
  if (!service || !service?.deactivationRule) return null

  const { value: deactivationRule, forecastDate: forecastDeactivationDate } =
    service?.deactivationRule

  const onDeactivatePhoneroCloud = () => {
    onDeactivate(product)
  }

  return (
    <>
      <PuxModal
        isOpen={isOpen}
        aria-label="Deactivate Phonero Cloud"
        onWillDismiss={() => onDismiss()}
      >
        <PuxDialogTitle
          withBackButton={true}
          withDismissButton={true}
          onDismissClick={onDismiss}
          onBackClick={onDismiss}
        >
          <PuxTypography variant="h3">Avslutt tjenesten</PuxTypography>
        </PuxDialogTitle>

        {/* Deactivate Phonero Sky */}
        {!futureDeactivationDate ? (
          <PuxSheet>
            <PuxTypography variant="h4" style={{ textAlign: "center" }}>
              {"Avslutt Phonero Sky"}
            </PuxTypography>

            {deactivationRule === DeactivationRule.Endofmonth &&
            forecastDeactivationDate ? (
              <PuxTypography variant="body1" style={{ textAlign: "center" }}>
                {"Tjenesten fornyes hver måned. Du kan avslutte når du selv ønsker, men senest 1 time før fornyelse. Tjenesten er aktiv ut inneværende måned og deaktiveres den " +
                  i18n.format(forecastDeactivationDate, DateFormat.Date) +
                  "."}
              </PuxTypography>
            ) : (
              <PuxTypography variant="body1" style={{ textAlign: "center" }}>
                {
                  "Tjenesten fornyes hver måned. Du kan avslutte når du selv ønsker, men senest 1 time før fornyelse."
                }
              </PuxTypography>
            )}

            <div className={cx(css.deactivateButtonsContainer)}>
              <PuxButton
                style={{ width: "200px" }}
                onClick={() => onDeactivatePhoneroCloud()}
                fill="solid"
                color="primary"
                size="default"
              >
                <PuxText>{"Bekreft avslutt"}</PuxText>
              </PuxButton>
              <PuxButton
                style={{ width: "200px" }}
                onClick={() => onDismiss()}
                color="medium"
                fill="solid"
                size="default"
              >
                <PuxText>{"Avbryt"}</PuxText>
              </PuxButton>
            </div>
          </PuxSheet>
        ) : (
          <>
            {/* Phonero Sky is deactivated with a future deactivation date */}
            <PuxSheet style={{ textAlign: "center" }}>
              <PuxTypography variant="h4" style={{ textAlign: "center" }}>
                {"Phonero Sky vil bli avsluttet"}
              </PuxTypography>

              {(futureDeactivationDate || forecastDeactivationDate) && (
                <>
                  <PuxTypography variant="body1">
                    {"Tjenesten avsluttes " +
                      i18n.format(
                        futureDeactivationDate ?? forecastDeactivationDate,
                        DateFormat.Date
                      ) +
                      "."}
                  </PuxTypography>
                  <PuxTypography variant="body1">
                    {
                      "Frem til denne datoen vil du fortsatt kunne bruke tjenesten."
                    }
                  </PuxTypography>
                </>
              )}

              <PuxTypography variant="body1" style={{ marginTop: "30px" }}>
                {" Ønsker du å avbryte oppsigelsen så gjør du det enkelt her."}
              </PuxTypography>
              <div className={cx(css.deactivateDismissButton)}>
                <PuxButton
                  style={{
                    width: "200px",
                    textAlign: "center",
                  }}
                  onClick={() => onDismiss()}
                  fill="solid"
                  color="medium"
                  size="default"
                >
                  <PuxText>{"Lukk"}</PuxText>
                </PuxButton>
              </div>
            </PuxSheet>
          </>
        )}
      </PuxModal>
    </>
  )
}
