import { PuxSheet, useBreakpoint } from "@phonero/pux-react"
import { Product, PuxItemProps } from "./ProductItem"
import { FC } from "react"
import { Perhaps } from "../util/typeHelpers"
import classNames from "classnames"
import { ProductItemCampaign } from "./ProductItemCampaign"
import css from "./DataPackage.module.scss"
// import { useAppTranslations } from "../util"

export interface CampaignBoostPackagesProps {
  boostCampaignProducts: Perhaps<(Product & { priceDiff?: number })[]>
  onSelectProduct?: any
  setSelected: React.Dispatch<React.SetStateAction<string | null>>
  widestAvailablePackageSize: any
  selected: string | null
  Component: any
}

export const CampaignBoostPackages: FC<
  CampaignBoostPackagesProps & PuxItemProps
> = ({
  boostCampaignProducts,
  selected,
  setSelected,
  widestAvailablePackageSize,
  ...puxItemProps
}) => {
  const xs = useBreakpoint("xs", { useMax: true })

  return (
    <>
      {!!boostCampaignProducts?.length && (
        <PuxSheet style={{ width: "100%", padding: xs ? "20px" : "30px" }}>
          <div
            className={classNames(css.campaignProductsContainer, {
              [css.campaignProductsContainerSingle]:
                boostCampaignProducts.length === 1,
              [css.campaignProductsContainerMultiple]:
                boostCampaignProducts.length > 1,
            })}
          >
            {boostCampaignProducts.map((product) => {
              return (
                <ProductItemCampaign
                  key={product?.id}
                  product={product}
                  onSelectProduct={setSelected}
                  {...puxItemProps}
                />
              )
            })}
          </div>
        </PuxSheet>
      )}
    </>
  )
}
