import { FunctionComponent } from "react"
import css from "./ExtraDataOrderStatus.module.scss"

export interface IExtraDataOrderStatus {
  children: any
}

export const ExtraDataOrderStatus: FunctionComponent<IExtraDataOrderStatus> = ({
  children,
}) => {
  return (
    <div>
      <div className={css.orderStatusContainer}>
        <div className={css.innerStyles}>{children}</div>
      </div>
    </div>
  )
}
