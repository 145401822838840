import { FC, useCallback, useEffect, useRef, useState } from "react"
import { useLocation } from "react-router-dom"
import { PuxFooter, PuxModal, PuxModalContent } from "@phonero/pux-react"

import { Checkout } from "./components/Checkout"
import {
  useCurrentSubscriptionId,
  SearchAndProductList,
  urlHashMap,
  CodewordType,
  InlineError,
  useAppTranslations,
  useAppQuery,
  useHasSpeedBoost,
  useAnalytics,
} from "@phonero/common-ux"

import useURLSearchParams from "../util/useURLSearchParams"
import DialogTitle from "../components/layout/DialogTitle"
import {
  ExtraDataPackageOfferingProduct,
  IProduct,
} from "@phonero/common-graphql/types"
import {
  CountriesDittDocument,
  SubscriptionExtraDataPackagesDocument,
} from "./ExtraDataQueries.generated"
import { useGoBack } from "../util/useGoBack"

const analyticsProps = {
  orderKind: "add-data",
}
export const AddDataDialog: FC = () => {
  const { subscriptionId } = useCurrentSubscriptionId()
  const { t } = useAppTranslations()
  const { pid, set } = useURLSearchParams({ pid: "" })
  const ref = useRef<HTMLPuxModalElement | undefined>()
  const { logOrderProcess, logOrderProcessCancellation, session } =
    useAnalytics()

  const { hash } = useLocation()
  const isOpen = urlHashMap[hash] !== undefined
  // We want a new session-id every time a user closes the dialog
  useEffect(() => {
    const sId = session.getSessionId()
    if (isOpen && !sId) {
      session.newSessionId()
      logOrderProcess({
        ...analyticsProps,
        stepName: "view",
      })
      return
    }
    if (!isOpen && sId) {
      session.resetSessionId()
      return
    }
  }, [logOrderProcess, isOpen, session])
  const { goBack } = useGoBack({
    onlyIfRouteMatch: [
      "data",
      "dataSubscription",
      "dataInternational",
      "dataInternationalSubscription",
    ],
  })

  const { data, error } = useAppQuery(SubscriptionExtraDataPackagesDocument, {
    variables: { subscriptionId },
    skip: !subscriptionId,
    onCompleted: (d) => {
      if (!d.mobileSubscription?.products) {
        return
      }
      logOrderProcess({
        ...analyticsProps,
        stepName: "got-products",
        attributes: {
          productCount: String(d.mobileSubscription.products.length),
        },
      })
    },
    // only fetch from cache here, as the <ChooseProduct>-will fetch the products needed (hopefully)
    // the query should be ecactly the same as <ChooseProduct>'s query
    fetchPolicy: "cache-only",
  })

  function setSelected(p: { id?: string } | undefined) {
    set((s) => ({ ...s, pid: p?.id || "" }))
  }
  const [selected, _setSelectedProduct] = useState<null | NonNullable<
    NonNullable<
      NonNullable<NonNullable<typeof data>["mobileSubscription"]>["products"]
    >[0]
  >>(null)
  const lastOrderStepName = useRef("deselect")
  const __setSelectedProduct: typeof _setSelectedProduct = (pf) => {
    _setSelectedProduct((state) => {
      const next = typeof pf === "function" ? pf(state) : pf

      if (isOpen) {
        if (next) {
          if (lastOrderStepName.current !== "select") {
            lastOrderStepName.current = "select"
            logOrderProcess({
              ...analyticsProps,
              stepName: "select",
              productID: next.id,
              productName: next.name,
            })
          }
        } else {
          if (lastOrderStepName.current !== "deselect") {
            lastOrderStepName.current = "deselect"
            logOrderProcess({ ...analyticsProps, stepName: "deselect" })
          }
        }
      }
      return next
    })
  }
  const setSelectedProduct = useCallback(__setSelectedProduct, [
    logOrderProcess,
    _setSelectedProduct,
    isOpen,
  ])

  useEffect(() => {
    if (!data?.mobileSubscription?.products) {
      return
    }
    if (!pid) {
      setSelectedProduct(null)
      return
    }
    const exact = data?.mobileSubscription?.products?.find((p) => p.id === pid)
    if (exact) {
      setSelectedProduct(exact)
      return
    }
    if (pid.includes("-")) {
      setSelectedProduct(null)
      return
    }
    // Allow linking with only the product-id (the last part),
    // but only if it is unique (it most likely is)
    const partial = data?.mobileSubscription?.products?.filter((p) =>
      p.id.endsWith(pid)
    )
    if (partial.length === 1) {
      set((s) => ({ ...s, pid: partial[0].id }))
      setSelectedProduct(partial[0])
      return
    }
    setSelectedProduct(null)
  }, [pid, data?.mobileSubscription?.products, setSelectedProduct, set])

  const onClose = () => {
    lastOrderStepName.current = "deselect"
    logOrderProcessCancellation()
    goBack()
  }

  function handleClose() {
    lastOrderStepName.current = "deselect"
    // We navigate back twice here, since the user is on checkout when this event occurs
    logOrderProcessCancellation()
    goBack({ amount: 2 })
  }

  return (
    <PuxModal
      ref={ref as any}
      keyboardClose
      swipeToClose
      isOpen={isOpen}
      onWillDismiss={selected ? handleClose : onClose}
    >
      <DialogTitle
        {...(!!selected && {
          onBackClick: () => setSelected(undefined),
          onlyIfRouteMatch: ["data", "dataInternational"],
        })}
      >
        {t("Actions.RefillData")}
      </DialogTitle>
      <InlineError error={error} code="SubscriptionExtraDataPackages" />

      {selected ? (
        <Checkout
          logOrderProcess={(params) =>
            logOrderProcess({
              ...analyticsProps,
              stepName: "confirm-purchase",
              ...params,
            })
          }
          product={selected as any}
          onClose={handleClose}
          onCancel={() => setSelected(undefined)}
        />
      ) : (
        <ChooseProduct
          onSelectProduct={setSelected as any /* TODO: Fix any hack */}
        />
      )}

      <PuxFooter />
    </PuxModal>
  )
}

const ChooseProduct: FC<{
  /* With sort-mode, all items are visible, but the closes matching are at the top */
  searchMode?: "sort" | "filter"
  /* If there are less than this amount of items, no search-input will be visible */
  minSearchItems?: number
  /* Can be used to override the tabs that should show or hide the search-input */
  forceSearchTab?: Partial<Record<CodewordType, boolean>>
  onSelectProduct: (p: Partial<IProduct>) => any
}> = (props) => {
  const { subscriptionId } = useCurrentSubscriptionId()
  const { error, data, loading } = useAppQuery(
    SubscriptionExtraDataPackagesDocument,
    {
      variables: { subscriptionId },
      skip: !subscriptionId,
      fetchPolicy: "cache-and-network",
    }
  )

  // nosemgrep: semgrep.gql-missing-error-check-warning
  const countryList = useAppQuery(CountriesDittDocument, {
    variables: {
      skipInEEA: false,
    },
  })
  const hasSpeedBoost = useHasSpeedBoost()

  // Temp, if speed boost is activated only data utland packages should be shown.
  const products = (
    data?.mobileSubscription?.products?.filter(
      (p) =>
        !hasSpeedBoost ||
        (p.__typename === "ExtraDataPackageOfferingProduct" &&
          (p as ExtraDataPackageOfferingProduct).type === 1)
    ) || []
  ).map((p) => {
    // this is used to add idditional search-parameters for certain countries
    if (p.name.includes("USA")) {
      return { ...p, additionalSearchmatches: "amerika america" }
    }
    if (p.name.includes("Amerika")) {
      return { ...p, additionalSearchmatches: "amerika america" }
    }
    return p
  })

  if (error) {
    return (
      <PuxModalContent>
        <InlineError error={error} code={"getExtraPackages"} />
      </PuxModalContent>
    )
  }

  return (
    <SearchAndProductList
      {...props}
      loading={loading}
      countryList={countryList}
      products={products as any}
      includeBoostProducts={false}
    />
  )
}
