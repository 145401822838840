import React, { FC } from "react"
import { externalLink } from "@phonero/pux-icons/icons"
import { PuxButton, PuxIcon } from "@phonero/pux-react"

import css from "./MobileInsuranceLinks.module.scss"

export function GetReportUrlForProduct(productId: number) {
  return productId === 24711
    ? "https://affinity.tryg.no/meld-skade-online/"
    : productId === 24712
    ? "https://affinity.tryg.no/meld-skade-online/idprotection/"
    : ""
}

export interface MobileInsuranceReportLinkProps {
  productId: number
  styleType: "button" | "anchor"
  buttonColor?:
    | "primary"
    | "secondary"
    | "tertiary"
    | "success"
    | "warning"
    | "danger"
    | "light"
    | "medium"
    | "dark" // PuxButton color
  buttonFill?: "solid" | "outline" | "clear" // PuxButton fill
}

export const MobileInsuranceReportLink: FC<MobileInsuranceReportLinkProps> = (
  props: MobileInsuranceReportLinkProps
) => {
  const url = GetReportUrlForProduct(props.productId)
  const text = "Meld Skade"
  const icon = <PuxIcon icon={externalLink} />
  if (props.styleType === "anchor") {
    return (
      <div className={css.insuranceLinkAnchor}>
        <a href={url} target="_blank" rel="noopener noreferrer">
          {text}
          {icon}
        </a>
      </div>
    )
  }
  return (
    <PuxButton
      className={css.insuranceLinkButton}
      strong
      fill={props.buttonFill ?? "clear"}
      size="small"
      color={props.buttonColor ?? "primary"}
      href={url}
      target="_blank"
      rel="noopener noreferrer"
    >
      {text}
      {icon}
    </PuxButton>
  )
}
