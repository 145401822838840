import * as Types from '@phonero/common-graphql/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type CancelDeactivateServiceMutationVariables = Types.Exact<{
  input: Types.CancelDeactivateServiceInput;
}>;


export type CancelDeactivateServiceMutation = { __typename: 'Mutation', cancelDeactivateService?: { __typename: 'OrderConfirmation', orderId: any } | null };


export const CancelDeactivateServiceDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CancelDeactivateService"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CancelDeactivateServiceInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"cancelDeactivateService"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"orderId"}}]}}]}}]} as unknown as DocumentNode<CancelDeactivateServiceMutation, CancelDeactivateServiceMutationVariables>;